<template>
    <div>
      <Toast position="top-right" />
      <div class="p-grid">
        <div  class="p-col-12 p-md-6 p-xl-4">
          <div class="card no-gutter widget-overview-box widget-overview-box-2">
            <span class="overview-title">Rapport d'execution soumis</span>
            <div class="p-grid overview-detail">
              <div class="p-col-6">
                <div class="overview-number">Total</div>
                <div class="overview-subtext"></div>
              </div>
              <div class="p-col-6">
                <div class="overview-number">0</div>
                <div class="overview-subtext"></div>
              </div>
            </div>
          </div>
        </div>
        <div  class="p-col-12 p-md-6 p-xl-4">
          <div class="card no-gutter widget-overview-box widget-overview-box-2">
            <span class="overview-title">Rapport d'execution non soumis</span>
            <div class="p-grid overview-detail">
              <div class="p-col-6">
                <div class="overview-number">Total</div>
                <div class="overview-subtext"></div>
              </div>
              <div class="p-col-6">
                <div class="overview-number">0</div>
                <div class="overview-subtext"></div>
              </div>
            </div>
          </div>
        </div>
        <div  class="p-col-12 p-md-6 p-xl-4">
          <div class="card no-gutter widget-overview-box widget-overview-box-2">
            <span class="overview-title">Plan Local non validé</span>
            <div class="p-grid overview-detail">
              <div class="p-col-6">
                <div class="overview-number">Total</div>
                <div class="overview-subtext"></div>
              </div>
              <div class="p-col-6">
                <div class="overview-number">0</div>
                <div class="overview-subtext"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="p-grid crud-demo">
        <FileViewer
          v-if="null !== fileViewer.url"
          :url-link="fileViewer.url"
          :original-url="fileViewer.originalUrl"
          :title="fileViewer.title"
          v-on:close="handleOnClose"
        />
        <div class="p-col-12">
          <div class="card">
            <!--:filters="filters"-->
            <!-- {{ getPlanList }} -->
            <DataTable
              ref="dt"
              :value="getPlanList?.data"
              data-key="id"
              responsiveLayout="scroll"
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              currentPageReportTemplate="{totalRecords} inscriptions"
            >
              <template #header>
                <div class="table-header p-d-flex p-flex-column p-flex-md-row p-jc-md-between p-ai-start p-ai-md-center">
                  <h5 class="p-mb-2 p-m-md-0">Gestion de plan de contenu local</h5>
                </div>
                <br />
                <div style="float: right">
                  <!--<h6 v-if="errorOnSearch" style="color: red">Aucune entreprise n'a été trouvée.</h6>
                  <h6 v-if="errorOnSearchSize" style="color: red">Le champ de recherche est vide.</h6>-->
                </div>
                <div class="filters">
                    <!-- <div class="filter__item">
                        <label class="filter__label" for="">Type</label>
                        <SelectButton
                            v-model="selectedType"
                            :options="types"
                            optionLabel="name"
                        >
                            <template #option="slotProps">
                            <div class="car-option">
                                <div class="p-px-3">{{ slotProps.option.name }}</div>
                            </div>
                            </template>
                        </SelectButton>
                    </div> -->
                    <div class="filter__item">
                    <label class="filter__label" for="">Année</label>
                    <Dropdown
                      id="state"
                      v-model="filterValues.status"
                      :options="[{name:'2023'}]"
                      optionLabel="name"
                    ></Dropdown>
                  </div>
                  <div class="filter__item">
                    <label class="filter__label" for="">Statut</label>
                    <Dropdown
                      id="state"
                      v-model="filterValues.status"
                      :options="dropdownValues"
                      optionLabel="name"
                    ></Dropdown>
                  </div>
                  <div class="filter__item">
                    <label for="entreprise-id" class="filter__label">Entreprise</label>
                    <InputText v-model="filterValues.entrepriseName" placeholder="Rechercher une entreprise" />
                  </div>
                  <div class="filter__item">
                    <label class="filter__label" for="profile-entreprise-id">Profil d'entreprise</label>
                    <Dropdown
                      id="profile-entreprise-id"
                      v-model="filterValues.companyProfile"
                      :options="tarifsAsArray"
                      optionLabel="adherant"
                    ></Dropdown>
                  </div>

                  <div class="filter--ml">
                    <div class="filter__item">
                      <label class="filter__label" for=""></label>
                      <Button
                        v-tooltip.top="'Exporter'"
                        icon="pi pi-download"
                        iconPos="right"
                        @click="exportData($event)"
                      />
                    </div>
                    <div class="filter__item">
                      <label class="filter__label" for=""></label>
                      <Button
                        class="p-button-help"
                        v-tooltip.top="'Effacer les filtres'"
                        icon="pi pi-filter-slash"
                        @click="resetFilter()"
                      />
                    </div>
                  </div>
                </div>
              </template>
              <!--<div v-if="errorFile" style="color: red">Ce fichier est corrompu.</div>-->
              <Column field="statut" header="Statut" :sortable="true">
                <template #body="slotProps"
                  >&nbsp;&nbsp;
                  <!-- {{ slotProps.data.status }} -->
                  <span :class="'pcl-badge ' + getStatusClassName(slotProps.data)">
                {{ enumMap[slotProps.data.status] }}</span>
                </template>
              </Column>
              <Column field="statut" header="Année" :sortable="true">
                <template #body="slotProps"
                  >&nbsp;&nbsp;
                  {{ slotProps.data.year }}
                </template>
              </Column>
              <Column field="details.name" header="Nom de l'entreprise" :sortable="true" bodyStyle="text-indent:1em">
                <template #body="slotProps"
                  >{{ slotProps.data.entreprise.name }}</template
                >
              </Column>
              <Column field="details.email" header="E-mail" :sortable="true" bodyStyle="text-indent:1em">
                <template #body="slotProps">{{ slotProps.data.entreprise.email }}</template>
              </Column>
              <Column field="price" header="Point Focal" :sortable="false" bodyStyle="text-indent:1em">
                <template #body="slotProps">{{ slotProps.data.entreprise.pointFocal.name }}</template>
              </Column>
              <Column field="price" header="Fichier joint" :sortable="false" bodyStyle="text-indent:1em">
                <template #body="slotProps">
                  <preview-file-btn
                    :title="'Plan de contenu local : '+slotProps.data.id"
                    :url="slotProps.data.documentPathUrl"
                    :btnLabel="'Visualiser'"
                    @triggered="handleShowPreview"
                  />
                </template>
              </Column>
  
              <Column field="action" header="Actions" style="width: 13em" :sortable="false" bodyStyle="text-indent:1em">
                <template #body="slotProps">
                  <span class="p-column-title">Actions</span>
                  <Button
                    icon="pi pi-eye"
                    class="p-button-outlined p-button-rounded p-button-submit"
                    v-tooltip.top="'Voir le formulaire'"
                    @click="onToggleDetails(slotProps.data)"
                  />&nbsp;
                  <!-- <Button
                    icon="pi pi-id-card"
                    v-tooltip.top="'Changer profil'"
                    class="p-button-outlined p-button-rounded p-button-danger"
                    @click="onChangeProfile(slotProps.data)"
                  /> -->
                </template>
              </Column>
            </DataTable>
  
            <Paginator :rows="25" :totalRecords="response?.meta.total" @page="onPage($event)"></Paginator>
  
            <!--Dialog v-model:visible="displayDialog" :style="{ width: '600px' }" header :modal="true" class="p-fluid">
              <div>
                <img v-auth-image="previewImage" />
              </div>
            </Dialog-->
  
            <Dialog
              v-model:visible="dialogStates.ValidationView"
              :style="{ width: '600px' }"
              header="Êtes-vous sûr de vouloir confirmer l'inscription ?"
              :modal="true"
              class="p-fluid"
            >
              <template #footer>
                <Button
                  label="NON"
                  class="p-button p-component p-button-outlined p-button-danger"
                  @click="handleHideDialogs"
                />
                <Button
                  :loading="loadings[1]"
                  label="OUI"
                  class="p-button p-component p-button-outlined p-button-success"
                  @click="handleOnConfirmValidation"
                />
              </template>
            </Dialog>
  
            <Dialog
              v-model:visible="dialogStates.RejectionView"
              :style="{ width: '450px' }"
              header="Motif du rejet d'inscription"
              :modal="true"
              class="p-fluid"
            >
              <div class="p-field">
                <label for="reject-message-id">Objet(s) du rejet</label>
                <Textarea
                  :class="{ 'p-invalid': !payload.rejectSubscription.message && submitted }"
                  v-model="payload.rejectSubscription.message"
                  id="reject-message-id"
                  required="true"
                  rows="10"
                  cols="25"
                />
                <small style="color: red" v-show="!payload.rejectSubscription.message && submitted">
                  Veuillez renseigner le motif du rejet.
                </small>
              </div>
              <template #footer>
                <Button
                  label="Annuler"
                  icon="pi pi-times"
                  class="p-button p-component p-button-outlined p-button-danger"
                  @click="handleHideDialogs"
                />
                <Button
                  :loading="loadings[2]"
                  label="Rejeter"
                  icon="pi pi-check"
                  class="p-button p-component p-button-outlined p-button-success"
                  @click="handleOnConfirmRejection"
                />
              </template>
            </Dialog>
  
            <Dialog
              v-model:visible="dialogStates.ChangeProfileView"
              :style="{ width: '450px' }"
              :header="`Changement du profil de ${detail?.entreprise?.name}`"
              :modal="true"
              class="p-fluid"
            >
              <div class="p-field">
                <label for="description">Profil</label>
                <Dropdown
                  id="new-profile-entreprise-id"
                  v-model="newProfileItem.rangSouscriptionEnCours"
                  :options="tarifsAsArray"
                  optionLabel="name"
                />
              </div>
              <div class="p-field">
                <label for="description">Montant</label>
                <InputText :disabled="true" type="text" v-model="newProfileItem.montantSouscriptionEnCours" />
              </div>
              <template #footer>
                <Button
                  label="Annuler"
                  icon="pi pi-times"
                  class="p-button p-component p-button-outlined p-button-danger"
                  @click="hideDialog"
                />
                <Button
                  :loading="loading"
                  label="Enregister les modifications"
                  icon="pi pi-check"
                  class="p-button p-component p-button-outlined p-button-success"
                  @click="setNewProfile"
                />
              </template>
            </Dialog>
  
            <Sidebar class="p-sidebar-md" v-model:visible="dialogStates.DetailsView" position="right">
              <h4>Détails de l'entreprise {{ selectedSubscription?.details.name }}</h4>
              <br />
              <company-detail-list :subscription="selectedSubscription" />
              <br />
              <div v-show="isWaitingSubscriptionValidate(selectedSubscription)">
                <Button
                  icon="pi pi-thumbs-down"
                  label="Rejeter"
                  class="p-button-outlined p-button-rounded p-button-danger"
                  @click="handleOnReject(selectedSubscription)"
                  :style="{ color: '#FBC02D' }"
                />
                &nbsp;&nbsp;
                <Button
                  class="p-button-outlined p-button-rounded p-button-success"
                  icon="pi pi-thumbs-up"
                  label="Valider"
                  @click="handleOnValidate(selectedSubscription)"
                />
              </div>
            </Sidebar>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import FileViewer from '@/components/FileViewer';
  import PreviewFileBtn from '@/components/preview-file-btn';
  import { BackendUrls } from '@/services/urlsConfigs';
  import LoggedUserMixin from '@/mixins/logged-user';
  import CompanyDetailList from '@/components/company-detail-list';
  import EnumFormatMixin from '@/mixins/enum-format';
  import DocumentManagerMixin from '@/mixins/document-manager';
  import http from '@/services/http.service';
  import Http from '@/services/http-v2.service';
  import moment from 'moment';
  import XLSX from 'xlsx';
  import { mapGetters,mapActions } from 'vuex';
  
  export default {
    mixins: [EnumFormatMixin, DocumentManagerMixin, LoggedUserMixin],
    components: {
      CompanyDetailList,
      FileViewer,
      PreviewFileBtn,
    },
    data: () => ({
        selectedType: {},
      types: [
        { name: 'Plan', code: 'Pl' },
        { name: 'Rapport', code: 'Ra' },
      ],
      dialogStates: {
        DetailsView: false,
        ValidationView: false,
        RejectionView: false,
        ChangeProfileView: false,
      },
      listData: [],
      response: {
        data: [],
        meta: {},
      },
      selectedSubscription: null,
      dropdownValues: [],
      filterValues: {
        status: { name: 'Plan soumis', id: EnumFormatMixin.F_SUBMITTED },
        year: { name: '2023', id: 2023 },
        entrepriseName: null,
        domaineMetierIds: [],
        companyProfile: null,
        email: null,
      },
      tarifsAsArray: [],
      tarifs: [],
      domainesMetiers: [],
      newProfileItem: {
        rangSouscriptionEnCours: null,
        montantSouscriptionEnCours: null,
      },
      loadings: [false, false, false, false, false],
      submitted: false,
      payload: {
        rejectSubscription: { message: '' },
      },
    }),
    mounted() {
      if (localStorage.getItem('token')) {
        this.selectedType = { name: 'Plan', code: 'Pl' };
        this.fetchPlans({params: 'status=F_SUBMITTED&sort=createdAt,asc'});
        this.fetchTarifs();
        // this.fetchSubscriptions('status=EN_COURS&sort=createdAt,asc');
      } else {
        this.$router.push('/login');
      }
    },
    computed: {   
      ...mapGetters({
        getPlanList: 'localContent/getPlanList',
        isRevokeDialogsOpened: 'dialog/getRevokeDialogStatus',
      }),
    },
    watch: {
      selectedType:{
        handler: function (value) {
            this.dropdownValues = [];
            if (value.code === 'Pl') {
                this.dropdownValues.push(
                    { name: 'Plan soumis', id: EnumFormatMixin.F_SUBMITTED },
                    { name: 'Plan à corriger', id: EnumFormatMixin.F_REJECTED },
                    { name: 'Plan validé', id: EnumFormatMixin.F_VALIDATED}
                );
                this.filterValues.status = { name: 'Plan soumis', id: EnumFormatMixin.F_SUBMITTED }
            }else{
                this.dropdownValues.push(
                    { name: 'Rapport soumis', id: EnumFormatMixin.R_SUBMITTED },
                    { name: 'Rapport à corriger', id: EnumFormatMixin.R_REJECTED },
                    { name: 'Rapport validé', id: EnumFormatMixin.R_VALIDATED },
                );
                this.filterValues.status = { name: 'Rapport soumis', id: EnumFormatMixin.R_SUBMITTED }
            }
        },
        deep: true,
      },
      filterValues: {
        handler: function (value) {
          this.refreshPage(value);
        },
        deep: true,
      },
      newProfileItem: {
        handler: function (event) {
          const rankId = event.rangSouscriptionEnCours?.id;
          this.newProfileItem.montantSouscriptionEnCours = this.tarifs[rankId].montant_xof;
        },
        deep: true,
      },
    },
    methods: {
        ...mapActions({
            fetchPlans: 'localContent/fetchPlans'
        }),
      getStatusClassName(localContent) {
        switch (localContent.status) {
          case ('F_CREATED' || 'R_CREATED'):
            return 'create';
          case ('F_SUBMITTED' || 'R_SUBMITTED'):
            return 'submitted';
          case ('F_REJECTED' || 'R_REJECTED'):
            return 'rejected';
          case ('F_VALIDATED' || 'R_VALIDATED'):
            return 'validated';
          default:
            return '';
        }
      },
  
      getParamsUrl(value) {
        // console.log(value);
        const keyMap = {};
        if (value.status.id) keyMap.status = value.status.id;
        if (value.entrepriseName) keyMap.enterpriseName = value.entrepriseName;
        if (value.companyProfile?.rang) keyMap.rank = value.companyProfile.rang;
        if (value.domaineMetierIds.length > 0) keyMap.domainMetierIds = value.domaineMetierIds.map((id) => id).join(',');
  
        return Object.keys(keyMap).reduce((url, key) => `${url}${key}=${keyMap[key]}&`, '');
      },
  
      // getStatus(subscription) {},
  
      onToggleDetails(localPlanDetails) {
        this.$router.push(`/local-content/step/${localPlanDetails.entreprise.name}/`);
        // this.selectedSubscription = subscription;
        // this.dialogStates.DetailsView = !this.dialogStates.DetailsView;
      },
  
      refreshPage(value) {
        console.log(value);
        // const params = this.getParamsUrl(value);
        // this.fetchSubscriptions(params);
      },
  
      fetchSubscriptions(parameters) {
        return Http.get(BackendUrls.GetListSubscriptions(parameters))
          .then(this.handleFetchSubscriptionSucceed)
          .catch(this.handleFetchSubscriptionFailed);
      },
  
      handleFetchSubscriptionSucceed(response) {
        // this.listData = response.data.data;
        this.response.data = response.data.data;
        this.response.meta = response.data.meta;
        return response;
      },
  
      handleFetchSubscriptionFailed() {},
  
      fetchTarifs() {
        http({
          method: 'get',
          url: '/tarifs',
        }).then((response) => {
          this.tarifsAsArray = response.data;
          this.tarifsAsArray.push({ id: null, adherant: 'Aucun', rang: null });
          this.tarifs = this.tarifsAsArray.reduce((h, tarif) => ({ ...h, [tarif.rang]: tarif }), {});
        });
      },
  
      exportData(event) {
        event.preventDefault();
        let filename = `${moment(new Date()).format('DDMMYYYYHHmmss')}-subscriptions-reports.xlsx`;
        let data = [];
        http.get(BackendUrls.GetListPayments()).then((response) => {
          data = data.concat(
            response.data.map((row) => {
              const entreprise = row.enterprise;
              return {
                STATUS: row.statut,
                ENTREPRISE: entreprise.name,
                POINT_FOCAL: entreprise.users.map((u) => `${u.firstName} ${u.lastName},${u.mobile},${u.phone}`).join('|'),
                EMAIL: entreprise.email,
                ENTREPRISE_TEL_1: entreprise.mobile,
                ENTREPRISE_TEL_2: entreprise.phone,
                POINT_FOCAL_TEL_1: entreprise.mobile,
                POINT_FOCAL_TEL_2: entreprise.phone,
                ADRESSE: entreprise.address,
                SITE_WEB: entreprise.www,
                PAYS: entreprise.name,
                PROFIL: this.tarifsAsArray.find((p) => p.rang === entreprise.rank)?.adherant,
                FORM_JURIDIQUE: entreprise.legalForm?.libelle,
                ID_FISCAL: entreprise.taxIdNumber,
                RC: entreprise.tradeRegister,
                DATE_CREATION: moment(entreprise.companyCreatedAt).format('DD/MM/yyyy'),
              };
            })
          );
          var ws = XLSX.utils.json_to_sheet(data);
          var wb = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, ws, 'Subscriptions');
          XLSX.writeFile(wb, filename);
        });
      },
  
      resetFilter() {
        this.filterValues.entrepriseName = null;
        this.filterValues.domaineMetierIds = [];
        this.filterValues.companyProfile = {};
        this.filterValues.email = null;
        if (this.selectedType.code === 'Pl') {

                this.filterValues.status = { name: 'Plan soumis', id: EnumFormatMixin.F_SUBMITTED }
            }else{

                this.filterValues.status = { name: 'Rapport soumis', id: EnumFormatMixin.R_SUBMITTED }
            }
      },
  
      handleOnReject(subscription) {
        this.selectedSubscription = subscription;
        this.dialogStates.RejectionView = !this.dialogStates.RejectionView;
      },
  
      handleOnValidate(subscription) {
        this.selectedSubscription = subscription;
        this.dialogStates.ValidationView = !this.dialogStates.ValidationView;
      },
  
      handleOnConfirmRejection() {
        this.submitted = true;
        const subscription = this.selectedSubscription;
        Http.post(BackendUrls.PostRejectSubscription(subscription.details.id, subscription.id), {
          message: this.payload.rejectSubscription.message,
        })
          .then(this.handleRejectionSucceed)
          .catch(this.handleRejectionFailed);
      },
  
      handleRejectionSucceed() {
        //TODO VUEX.
        this.refreshPage(this.filterValues);
        this.handleHideDialogs();
        this.$toast.add({
          severity: 'success',
          summary: 'Succès',
          detail: "L'abonnement a bien été rejeté.",
          life: 3000,
        });
      },
  
      handleRejectionFailed() {
        this.$toast.add({
          severity: 'error',
          summary: 'Erreur',
          detail: "Echec du rejet de l'abonnement.",
          life: 3000,
        });
      },
  
      handleOnConfirmValidation() {
        const subscription = this.selectedSubscription;
        Http.post(BackendUrls.PostValidateSubscription(subscription.details.id, subscription.id), {
          state: 'CONTRACTOR',
        })
          .then(this.handleValidationSucceed)
          .catch(this.handleValidationFailed);
      },
  
      handleValidationSucceed() {
        this.refreshPage(this.filterValues);
        this.handleHideDialogs();
        this.$toast.add({
          severity: 'success',
          summary: 'Succès',
          detail: "L'abonnement a bien été validé.",
          life: 3000,
        });
      },
  
      handleValidationFailed() {
        this.$toast.add({
          severity: 'error',
          summary: 'Erreur',
          detail: "Echec de la validation de l'abonnement.",
          life: 3000,
        });
      },
  
      handleHideDialogs() {
        this.dialogStates.ChangeProfileView = false;
        this.dialogStates.ValidationView = false;
        this.dialogStates.RejectionView = false;
        this.dialogStates.DetailsView = false;
      },
  
      onChangeProfile(subscription) {
        const payload = {
          entrepriseId: subscription.details.id,
          rangSouscriptionEnCours: this.newProfileItem.rangSouscriptionEnCours,
          montantSouscriptionEnCours: 0,
        };
        http
          .post('/enterprises/setClassification', payload, this.config)
          .then(() => {
            this.hideDialog();
            this.$toast.add({
              severity: 'success',
              summary: 'Accomplie',
              detail: "Le profil de l'entreprise a bien été mis à jour.",
              life: 7000,
            });
          })
          .catch(() => {
            this.$toast.add({
              severity: 'error',
              summary: 'Echec',
              detail: 'Une erreur est survenue lors du changement de profile. Veuillez contacter votre administrateur.',
              life: 7000,
            });
          });
      },
  
      onPage(event) {
        const urlParams = `${this.getParamsUrl(this.filterValues)}page=${event.page}&sort=createdAt,asc`;
        this.fetchSubscriptions(urlParams);
      },
    },
  };
  </script>
  
  <style scoped lang="scss">
  .filters {
    display: flex;
  }
  .filter__item {
    display: inline-grid;
    margin-right: 5px;
  }
  .filter__label {
    margin: 6px 1px;
    font-weight: 500;
  }
  
  .filter--ml {
    margin-left: auto;
  }
  
  .product-image {
    width: 100px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  }
  
  .p-dialog .product-image {
    width: 150px;
    margin: 0 auto 2rem auto;
    display: block;
  }
  
  .confirmation-content {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .pcl-badge {
    border-radius: 2px;
    padding: 0.25em 0.5rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: 0.3px;
  
    &.create {
      background: #b5dbf0;
      color: #0288d1;
    }
  
    &.submitted {
      background: #feedaf;
      color: #fdc602;
    }

    &.rejected {
      background: #c63737;
      color: #e6a9a9;
    }
    &.validated {
      background: #256029;
      color: #9cf4a2;
    }
  }
  </style>
  